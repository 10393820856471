.ContactBoxContainer {
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2em;
  height: 10em;
  align-items: center;
}

.ContacBoxLeft {
  width: 50%;
  text-align: left;
}

.ContacBoxRight {
  width: 40%;
  text-align: center;
  padding-top: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ContacBoxTitle {
  text-transform: uppercase;
  font-family: "Cupra-Regular", Helvetica, sans-serif;
  font-size: 27px;
}

.HeaderContainer {
  padding-top: 4em;
  padding-bottom: 3em;
}

.Eyebrow {
  font-size: 20px;
  padding-bottom: 12px;
  font-family: "Cupra-Light", Helvetica, sans-serif;
}

.ContactBoxList {
  background-color: white;
  width: 95%;
  /* max-width: 100%; */
  /* max-width: 1060px; */
  /* margin: 0 auto; */
}

.RequestContainer {
  /* padding: 1em; */
  padding-bottom: 40px;
}

.SubTitles {
}

@media (max-width: 768px) {
  .ContactBoxContainer {
    flex-direction: column;
    /* padding-top: 2em; */
    padding-bottom: 2em;
    height: auto;
    width: 90%;
    margin: 0 auto;
  }

  .ContacBoxLeft {
    width: 100%;
  }

  .ContacBoxRight {
    width: 100%;
  }

  .HeaderContainer {
    width: 50%;
    padding-top: 2em;
    padding-bottom: 32px;
    padding-left: 24px;
    /* margin-left: 5%; */
  }

  .ContacBoxTitle {
    font-size: 20px;
    padding-top: 20px;
  }

  .Eyebrow {
    font-size: 14px;
  }

  .ContactBoxList {
    background-color: #f8f8f8;
    width: 100%;
  }

  .SubTitles {
    line-height: 20px;
    /* padding-bottom: 26px; */
  }

  .SingleSubtitle {
    margin: 0;
    width: 84%;
  }

  .RequestContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
