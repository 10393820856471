.HeaderDiv {
  position: relative;
  height: 26vw;
  max-height: 800px;
  min-height: 422px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  bottom: 40px;
}

@media (min-width: 768px) {
  .HeaderDiv {
    font-size: 19px;
    align-items: center;
    bottom: 0px;
  }
}
