.Button {
    cursor: pointer;
    text-decoration: none;
    background: black;
    padding: 0 1.5em;
    border: 1px solid black;
    border-radius: 3px;
    text-align: center;
    margin-bottom: 1.25rem;
    user-select: none;
    transition: 0.5s;
    color: white;
    margin: 0 auto;
    width: 200px;
    font-family: "Cupra-Regular",Helvetica,sans-serif;
    font-size: 14px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Button:hover {
    background-color: grey;
}

.ButtonLeftSide {
    margin: 0;
}

.ButtonTransparent {
    background-color: transparent;
    color: black;
}

.ButtonTransparent:hover {
    background-color: black;
    color: white;
}

.ContactButton {
    background-color: #DBD3CB;
    color: black;
    width: 100%;
    max-width: 180px;
}

.ContactButton:hover {
    background-color: black;
    color: white;
}

.LargeButtonText {
    font-size: 16px;
}

@media(max-width: 768px){
    .Button {
        width: 100%;
        margin: 0;
        padding: 0.5em 0em;
        max-width: none;
    }

    .SmallButtonText {
        font-size: 12px;
    }
}
