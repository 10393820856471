@font-face {
  font-family: "Cupra-Regular";
  src: url("../fonts/Cupra-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cupra-Bold";
  src: url("../fonts/Cupra-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Cupra-Light";
  src: url("../fonts/Cupra-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Cupra-Book";
  src: url("../fonts/Cupra-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Cupra-Medium";
  src: url("../fonts/Cupra-Medium.ttf") format("truetype");
}

html,
body {
  background-color: #f9f9f8;
  font-family: "Cupra-Book", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.Container {
  max-width: 1436px;
  margin: 0 auto;
  padding: 0 24px;
}

#HeaderBackground {
  background: url("../assets/MicrosoftTeams-image (30).png") no-repeat center
    center;
  background-size: cover;
  background-position: 43.5% 0em;
}

h1,
h2 {
  font-weight: normal;
}

.Title {
  bottom: 30%;
  font-size: 2em;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .Container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  #HeaderBackground {
    background-position: center 20%;
  }
}


