.ContactBlocks {
  margin: 0 auto;
  padding-bottom: 2em;
  display: flex;
  align-items: stretch;
  padding-left: 2em;
}

.HeaderWrapper {
  padding-top: 55px;
  /* padding-bottom: 50px; */
}

.PhoneContact {
  display: flex;
  flex-direction: row;
  /* margin: 0 auto; */
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /* justify-content: space-between; */
  width: 98%;
}

.PhoneHeaderContainer {
  text-align: left;
  padding-top: 2em;
  padding-bottom: 2em;
  margin-left: 4em;
}

.PhoneHeaderTitle {
  font-size: 2em;
  text-transform: uppercase;
  font-family: "Cupra-Regular", Helvetica, sans-serif;
  font-size: 27px;
}

.PhoneHeaderTitleContent {
  padding-bottom: 1em;
}

.PhoneData {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 15%;
  flex-shrink: 0;
}

.PhoneNumber {
  font-family: "Cupra-Light", Helvetica, sans-serif;
  font-size: 27px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.ContainerBottom {
  padding-bottom: 4em;
}

.Eyebrow {
  font-family: "Cupra-Light", Helvetica, sans-serif;
  font-size: 20px;
  padding-bottom: 8px;
}

@media (max-width: 768px) {
  .ContactBlocks {
    display: block;
    padding: 0;
  }
  .PhoneContact {
    width: 100%;
    flex-direction: column;
  }

  .HeaderWrapper {
    padding-top: 3em;
  }

  .ContainerBottom {
    padding-bottom: 0em;
  }

  .Eyebrow {
    font-family: "Cupra-Light", Helvetica, sans-serif;
    font-size: 14px;
    padding-bottom: 12px;
  }
}

.AdditionalLink {
  padding: 0;
  margin-bottom: 0;
}
.AdditionalLink a {
  color: black;
  text-underline-offset: 1px;
}

.AdditionalLink a:hover {
  opacity: 0.5;
}

a.blackLink,
a.blackLink:visited {
  color: black;
}
