.WelcomeContainer {
  display: none;
}

.WelcomeTextContainer {
  color: white;
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
}

.WelcomeText {
  font-size: 14px;
}

.WelcomeTextTop {
  padding-bottom: 2em;
  font-family: "Cupra-Regular", Helvetica, sans-serif;
  font-size: 27px;
  text-transform: uppercase;
}

@media(min-width: 768px){
  .WelcomeText {
    width: 548px;
    font-size: 16px;
  }

  .WelcomeContainer {
    display: flex;
    padding-top: 75px;
    padding-bottom: 106px;
    /* padding-left: 10%; */
  }

  .WelcomeTextTop {
    padding-bottom: 1em;
  }
}

