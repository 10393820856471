.ContactBlocks {
  display: flex;
  width: 80%;
  flex-direction: row;
  margin: 0 auto;
}

.ContactBlockContainer {
  text-align: left;
  /* padding: 2em; */
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 100%;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ContactBlockTitle {
  text-transform: uppercase;
  font-family: "Cupra-Regular", Helvetica, sans-serif;
  font-size: 27px;
}

.ContactBlockTitleContent {
  padding-bottom: 30px;
}

.ContactBlockNarrow {
  width: 285px;
}

.CupraLinkBlock {
  text-align: center;
}

.HalfBlock {
  width: 420px;
  margin-left: 2em;
}

@media (max-width: 768px) {
  .ContactBlocks {
    width: 100%;
  }
  .ContactBlockContainer {
    margin: 0;
    padding: 0;
    padding-bottom: 50px;
  }

  .ContactBlockTitle {
    font-size: 20px;
  }

  .ContactBlockNarrow {
    width: 100%;
  }

  .HalfBlock {
    width: 100%;
  }
}
