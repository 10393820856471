.HeadingBlock {
  text-align: left;
}

.HeadingBlockTitle {
  text-transform: uppercase;
  font-family: "Cupra-Light", Helvetica, sans-serif;
  font-size: 33px;
  margin: 0;
}

.HeadingWhiteFont {
  color: white;
}

.HeadingToParentsBottom {
  bottom: 0px;
}

@media (min-width: 768px) {
  .HeadingBlockTitle {
    font-size: 54px;
  }
}
