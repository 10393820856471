.CupraLinksContainer {
  background-color: #1b1b1b;
  color: white;
  align-items: center;
  /* margin: 0 auto; */
  padding-top: 3em;
  padding-bottom: 3em;
  width: 95%;
}

.Divider {
  width: 0;
  border-left: 1px solid #dbd3cb;
  height: 124px;
  align-self: center;
}

.CupraLinksContactBlocks {
  display: flex;
  align-items: flex-end;
  line-height: 1em;
  justify-content: space-between;
}

.CupraLinksTitle {
  font-family: "Cupra-Regular", Helvetica, sans-serif;
  font-size: 28px;
  text-align: center;
  /* padding-top: 1em; */
  text-transform: uppercase;
  margin-right: 26%;
  padding-bottom: 38px;
  margin: 0 auto;
}

.CupraLinkParagraph {
  width: 105%;
}

@media (max-width: 768px) {
  .Divider {
    display: none;
  }

  .CupraLinksContactBlocks {
    flex-direction: column;
  }

  .CupraLinksTitle {
    padding-bottom: 40px;
    margin: 0 auto;
  }

  .CentralBox {
    position: relative;
    width: 100%;
  }

  .CentralBox::before {
    content: "";
    display: block;
    position: absolute;
    top: -2em;
    width: 50%;
    left: 25%;
    border-top: 1px solid #dbd3cb;
    display: none;
  }

  .CentralBox::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 2em;
    width: 50%;
    left: 25%;
    border-top: 1px solid #dbd3cb;
    display: none;
  }

  .CupraLinksContainer {
    /* padding-top: 45px; */
  }
}
